
/*******************************
General Styles
*******************************/

// @import url('https://fonts.googleapis.com/css?family=Raleway:300,400,600,700,800,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

html {
  color: $color-text-dark;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-optical-sizing: auto;
}

body {
  background: $color-brand2;
  
  &.noscroll {
    overflow: hidden;
  }

  // Solution for this issue: https://stackoverflow.com/questions/69051008/react-injecting-iframe-with-max-z-index-on-reload-after-changes-development 
  > iframe {
    display: none !important;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: $color-text;
  margin: 50px 0 20px;
}

h1 {
  font-size: 52px;
  font-weight: 800;
  // letter-spacing: -2px;
}

h2 {
  font-size: 48px;
  font-weight: $black;
  // letter-spacing: -1.5px;
}

h3 {
  font-size: 36px;
  font-weight: $xbold;
  // letter-spacing: -1px;
}

h4 {
  font-size: 32px;
  font-weight: $semi-bold;
  // letter-spacing: -.5px;
}

h5 {
  font-size: 28px;
  font-weight: $xbold;
  // letter-spacing: -.25px;
}

a {
  cursor: pointer;
  color: $color-text;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

p {
  font-size: 21px;
  margin: 0 0 20px;

  &.small {
    font-size: 16px;
  }
}

hr {
  border: none;
  border-bottom: 1px solid rgba(0,0,0,0.15);
  margin: 40px 0;
}

button,
.button {
  background-color: rgba(white, 0.95);
  border: 1px solid $color-text-dark;
  border: none;
  border-radius: 25px;
  box-shadow: 0 10px 18px -5px rgba(black, .20);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: $semi-bold;
  height: 46px;
  color: $color-text-dark;
  text-align: center;
  padding: 0 24px;
  outline: none;
  transition: transform 200ms, box-shadow 200ms;
  transform: scale(1);

  &:hover {
    transform: scale(1.085);
    box-shadow: 0 20px 30px -5px rgba(black, .20);
    text-decoration: none;
  }

  &.large {
    font-weight: $bold;
    height: 52px;
    padding: 0 28px;
  }
}

.roles-platforms {
  display: flex;
  margin: 15px 0;
  color: $color-text;

  > div {
    display: flex;
    align-items: center;
    margin-right: 25px;
    font-size: 16px;

    > i {
      margin-right: 8px;
      font-size: 24px;
    }
  }
}