
/*******************************
Main Wrapper
*******************************/

.main-wrapper {
  position: relative;
  z-index: 1;

  .banner-wrapper {
    position: fixed;
    inset: 0;
    z-index: 1;
  }

  .content-wrapper {
    background-color: rgba(white, .01);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    position: relative;
    z-index: 2;
    margin-top: 100vh;
  }
}