
/*******************************
Hero Banner
*******************************/

.banner-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 30px;
  display: flex;
  min-height: 650px;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  @include break-max($break-sm) {
    padding: 0 15px;
  }

  .hero-dots {
    position: absolute;
    inset: 0;
    z-index: 1;
  
    .dot-wrapper {
      position: absolute;
  
      @for $i from 0 through 74 {
        &-#{$i} {
          top: random(100) * 1%;
          left: random(100) * 1%;
          animation: heroDotsFlying (random(50)+20)+s ease-in-out calc(random(100)/-10)+s infinite alternate; 
        }
      }  
    }
    
    .dot {
      width: 5px;
      height: 5px;
      background: rgba(white, .5);
      border-radius: 2px;
      
      @for $i from 0 through 49 {
        &-#{$i} {
          transform-origin: (random(30)-15)+px (random(30)-15)+px;
          animation: heroDotsRotating (random(20)+10)+s ease-in-out calc(random(100)/-10)+s infinite; 
        }
      }      
    }

    @include break-max($break-med) {
      .dot-wrapper {
        @for $i from 0 through 50 {
          &-#{$i} {
            display: none;
          }
        }
      }
    }

    @include break-max($break-lrg) {
      .dot-wrapper {
        @for $i from 0 through 37 {
          &-#{$i} {
            display: none;
          }
        }
      }
    }
  }

  .work-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: $bold;
    color: white;

    > i {
      display: block;
      margin-top: 5px;
      font-size: 24px;
      transition: 400ms top;
      position: relative;
      top: 0;
    }

    &:hover {
      text-decoration: none;

      > i {
        top: 10px;
      }
    }

    @include break-max($break-sm) {
      margin-top: -40px;
      margin-bottom: 20px;
    }
  }
  
  .banner-info {
    color: white;
    width: 100%;
    max-width: $site-max-width;
    margin: auto;
    text-align: center;
    position: relative;
    z-index: 2;

    .name {
      font-size: 21px;
      font-weight: $xbold;
      margin-bottom: 25px;
    }
  
    > p {
      margin: 0 0 15px;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
  
      &:last-child {
        margin: 0;
      }

      @include break-max($break-sm) {
        font-size: 13px;
      }
    }

    > h1 {
      background: linear-gradient(90deg, #6CDC7E 0%, #008CFF 80%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 40px auto 25px;
      max-width: 500px;
      font-size: 38px;
  
      @include break-min($break-med + 1) {
        font-size: 52px;
        max-width: 600px;
      }
  
      @include break-min($break-lrg + 1) {
        font-size: 62px;
        max-width: 740px;
      }

      @include break-min($break-xlrg + 1) {
        font-size: 72px;
        max-width: 900px;
      }
    }

    .button {
      margin: 30px 0 0;
    }
  }
}