
/*******************************
About section
*******************************/

.brand-experience {
  background: $color-bg-dark;
  padding: 40px;

  @include break-min($break-med) {
    padding: 80px;
  }

  @include break-min(960px) {
    padding: 80px 0;
  }

  .brand-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .line {
      width: 1px;
      background-color: $color-highlight;
      height: 100px;

      @include break-min($break-med) {
        height: 200px;
      }
    }

    h4 {
      color: white;
      font-weight: $light;
      text-align: center;
      margin: 40px 0 0;
      max-width: 560px
    }

    > img {
      display: block;
      width: 100%;
      max-width: 850px;
      margin: 30px 0;
    }
  }
}


.about {
  padding-bottom: 200px;

  .about-info-wrap {
    width: 100%;
    max-width: 1120px;
    margin: auto;
  }

  .about-top,
  .about-bottom {
    padding: 30px;

    @include break-min($break-sm) {
      padding: 60px;
    }

    @include break-min(960px) {
      display: flex;
      min-height: 340px;
      padding: 0;
    }

    h5 {
      margin: 0 0 20px;
      color: white;
    }

    p {
      margin: 0 0 20px;
      font-size: 18px;
      line-height: 28px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .about-top {
    background-color: $color-bg-dark;

    .about-image {
      background-size: cover;
      background-position: center top;
      display: block;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      width: 100%;
      flex: 0 0 340px;

      &::after {
        display: block;
        content: '';
        padding-bottom: 100%;
      }

      @include break-min($break-sm) {
        max-width: 340px;
      }

      @include break-min(960px) {
        margin: 0;
      }
    }

    .about-info1 {
      align-self: stretch;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: white;

      p {
        font-size: 21px;
        line-height: 1.5;
      }
    }

    @include break-min(960px) {
      .about-info1 {
        padding: 0 60px;
        margin: 40px 0;
      }
    }
  }

  .about-bottom {
    align-items: center;
    background: $color-off-white;
    
    .about-info2 {
      margin-bottom: 40px;
    }

    .contact {
      flex: 0 0 340px;

      > div {
        display: flex;
        align-items: center;
        color: $color-text;
        font-size: 18px;
        font-weight: $bold;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        > i {
          font-size: 24px;
          margin-right: 20px;
        }
      }
    }

    @include break-min(960px) {
      .about-info2 {
        padding: 0 60px;
        margin: 40px 0;
      }
    }
  }
}