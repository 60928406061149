
/*******************************
Mixins and Keyframes
*******************************/

// Mixins

@mixin max-width() {
  max-width: $site-max-width;
  margin: auto;
}


// Keyframes

@keyframes showHeader {
  0% {
    height: 0;
  }

  100% {
    height: $header-fixed-height;
  }
}

@keyframes gridHover {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

// Hero Dots Flying
  
@keyframes heroDotsFlying {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(20px, 50px);
  }
  20% {
    transform: translate(-30px, 10px);
  }
  30% {
    transform: translate(10px, 60px);
  }
  40% {
    transform: translate(50px, 00px);
  }
  50% {
    transform: translate(-10px, -40px);
  }
  60% {
    transform: translate(-40px, 20px);
  }
  70% {
    transform: translate(30px, -30px);
  }
  80% {
    transform: translate(0px, -60px);
  }
  90% {
    transform: translate(40px, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}

// Hero Dots Rotating
  
@keyframes heroDotsRotating {
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }
  25%, 75% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: rotate(360deg);
  }
}