
/*******************************
Project Section (Home Page)
*******************************/

.project-section-wrapper {
  padding: 80px 0 20px;
  @include max-width();
  
  @include break-min($break-xlrg + 1) {
    padding: 120px 0 20px;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 40px;
    width: 100%;
    margin: auto;
  
    @include break-min($break-med + 1) {
      padding: 80px $padding-small;
    }
  
    @include break-min($break-xlrg + 1) {
      padding: 120px $padding-small;
    }

    &.title:first-child {
      padding-top: calc($header-fixed-height / 2);
    }
  
    h2 {
      color: white;
      margin: 0;
      position: relative;
      text-align: center;

      @include break-max($break-med) {
        font-size: 38px;
      }
  
      &::after {
        background: $color-brand1;
        display: block;
        content: '';
        height: 3px;
        width: 40px;
        position: absolute;
        bottom: -18px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .title:first-child {
    padding-top: 0;
  }
}

.project-section {
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  box-shadow: 0 0 0 0 transparent;
  position: relative;
  overflow: hidden;
  margin: 10px;
  transform: scale(1);
  transition: box-shadow 300ms, border-radius 300ms, transform 300ms, ;

  &:hover {
    @include break-min($break-lrg + 1) {
      box-shadow: 0 30px 50px -20px rgba(0,0,0,.42);
      transform: scale(1.08);
    }
  }

  @include break-min($break-sm + 1) {
    margin: 30px;
  }
  @include break-min($break-med + 1) {
    margin: 50px;
  }
  @include break-min($break-lrg + 1) {
    margin: 70px;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  @include break-min($break-lrg + 1) {
    display: flex;
    align-items: stretch;
    justify-content: center;
    min-height: 500px;
    padding: 60px 40px;
  }

  @include break-min($break-xlrg + 1) {
    min-height: 600px;
  }

  .section-info-wrap {
    position: relative;
    width: 100%;
    z-index: 2;

    @include break-min($break-lrg + 1) {
      display: flex;
      align-items: center;
    }
  }

  .section-details {
    flex: 0 0 40%;
    margin-left: auto;
    padding: 40px 40px 20px;
    position: relative;
    z-index: 2;
    order: 2;

    @include break-min($break-med + 1) {
      padding: 60px 60px 20px 40px;
    }

    @include break-min($break-lrg + 1) {
      padding: 0 80px 0 60px;
    }

    h3 {
      margin: 0 0 15px;
      
      @include break-max($break-med) {
        font-size: 32px;
      }
    }

    p {
      &:not(.roles-label) {
        line-height: 28px;
        margin-bottom: 30px;
      }
      
      @include break-max($break-lrg) {
        font-size: 18px;
      }

      &.roles-label {
        margin: 0;
        font-weight: $bold;
      }
    }

    .see-more-btn {
      margin-top: 20px;
    }

    &.light {
      h3,
      p,
      .roles-platforms {
        color: white;
      }
    }
  }

  .section-image {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 1;
    position: relative;
    order: 1;

    @include break-min($break-lrg + 1) {
      flex: 0 0 60%;
      height: 100%;
    }

    .image {
      display: block;
      width: 100%;
      height: auto;
      position: relative;
    }
  }

  .section-image-fill {
    background-size: cover;
    background-position: center;
    width: 100%;

    &::after {
      display: block;
      content: '';
      padding-bottom: 70%;
    }

    @include break-min($break-lrg + 1) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &.producer {
    @include break-min($break-lrg + 1) {
      min-height: 800px;
    }

    @include break-max($break-lrg) {
      // background-image: linear-gradient(90deg, #F9FAFC 0%, #EFF3F5 100%) !important;
      background-color: #f2f3f7 !important;
      background-size: 100%;
      background-position: bottom;
      background-repeat: no-repeat;
    }

    @include break-max($break-lrg) {
      .section-image-fill {
        background-position: left;
      }
    }
  }
}