
/*******************************
Header
*******************************/

header {
  box-sizing: content-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 0 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;

  @include break-min($break-med) {
    padding: 0 50px;
  }

  @include break-min($break-lrg) {
    padding: 0 80px;
  }

  .name {
    margin: 0 auto 0 30px;
    font-size: 16px;
    font-weight: $bold;
    color: white;
    position: relative;
    z-index: 1;

    @include break-max($break-sm) {
      font-size: 16px;
    }
  }

  &.fixed {
    animation: showHeader 0.2s;
    background-color: rgba($color-bg-dark, .8);
    box-shadow: 0 20px 60px rgba(0,0,0,0.15);
    height: $header-fixed-height;
    position: fixed;
    padding: 0 60px;

    @include break-max($break-med) {
      padding: 0 30px;
    }
  }

  a.logo-wrap {
    display: block;
    position: relative;
    z-index: 3;

    .logo {
      display: block;
      width: auto;
      height: 32px;
    }
  }

  > nav {
    display: flex;
    align-items: center;

    > a {
      font-size: 14px;
      font-weight: $semi-bold;
      color: white;

      &:not(:first-child) {
        margin-left: 25px;
      }
    }

    @include break-max($break-med) {
      background: $color-bg-dark;
      box-shadow: 0 5px 20px -5px rgba(0,0,0,0.3);
      justify-content: center;
      flex-direction: column;
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: auto;
      padding: 40px 20px;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.15s;
      z-index: 2;
      overflow: auto;

      > a {
        margin: 20px 0 !important;
      }

      &.active {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }
  
  .mobile-menu-btn {
    padding: 5px;
    cursor: pointer;
    position: relative;
    z-index: 100;
    margin-left: auto;

    @include break-min($break-med + 1) {
      display: none;
    }

    > span {
      background-color: white;
      border-radius: 1px;
      display: block;
      width: 20px;
      margin: 4px 0;
      height: 2px;
      transition: transform 0.2s, left 0.2s;
      position: relative;
    }

    &.active {
      > span {                
        &:nth-child(1) {
          transform: rotate(45deg);
          transform-origin: top left;
          left: 3px;
          top: -1px;
        }

        &:nth-child(2) {
          transform: scaleX(0);
          transform-origin: center;
        }
        
        &:nth-child(3) {
          transform: rotate(-45deg);
          transform-origin: bottom left;
          left: 3px;
          bottom: -1px;
        }
      }
    }
  }
}