
/*******************************
Project Page
*******************************/

.close-project {
  border-radius: 50%;
  background: rgba(white, .7);
  cursor: pointer;
  position: fixed;
  top: 30px;
  left: 30px;
  padding: 5px;
  z-index: 4;
  transform: scale(1);
  transition: 150ms transform;

  @include break-max($break-med) {
    top: 20px;
    left: 20px;
  }

  > i {
    color: black;
    position: relative;
    font-size: 30px;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.project-wrapper {
  background-color: black;
  position: fixed;
  inset: 0;
  padding: 16px;
  z-index: 3;
  opacity: 0;
  overflow: hidden;

  @include break-max($break-med) {
    padding: 8px;
  }
}

.project {
  background-color: white;
  border-radius: 16px;
  transform: translateY(40px);
  transition: opacity 350ms, transform 350ms;
  overflow: auto;
  max-height: 100%;
  
  .project-header {
    display: flex;
    align-items: center;
    height: 260px;
    position: relative;
    overflow: hidden;

    .title {
      color: $color-text;
      display: block;
      margin: auto;
      padding: 0 30px;
      width: 100%;
      height: auto;
      max-width: $content-width;
      opacity: 0;
      transition: 0.2s opacity 0.35s;

      h3 {
        font-size: 52px;
        margin: 0 0 15px;

        @include break-max(960px) {
          font-size: 42px;
        }

        @include break-max($break-sm) {
          font-size: 38px;
        }
      }

      .roles-platforms {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 -10px 0;

        > div {
          margin: 10px;
        }
      }

      @include break-max(960px) {
        position: relative;
        top: 30px;
      }
      
      @include break-min($break-sm) {
        padding: 0 60px;
      }
    }

    &.light .title {
      h3,
      .roles-platforms {
        color: white;
      }
    }

    .project-header-bg {
      background: $color-silver;
      transition: transform 0.35s ease-in-out 0.05s;
      transform-origin: right;
      transform: scaleX(0);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      pointer-events: none;

      &::after {
        background-image: linear-gradient(225deg, rgba(255,255,255,0.00) 0%, #FFFFFF 100%, rgba(255,255,255,0.13) 100%);
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.25;
      }
    }
  }

  .project-main {
    max-width: $content-width;
    width: 100%;
    margin: auto;
    padding: 0 30px;

    h4 {
      margin: 40px 0 20px;

      @include break-min($break-med) {
        margin: 50px 0 20px;
      }

      @include break-max($break-sm) {
        font-size: 28px;
      }
    }

    p {
      line-height: 36px;

      @include break-max($break-med - 1) {
        font-size: 18px;
        line-height: 32px;
      }
    }

    img {
      display: block;
      margin: 30px 0;
      width: calc(100% + 60px);
      margin-left: -30px;

      @include break-min($break-lrg) {
        width: calc(100% + 200px);
        margin-left: -100px;
      }
    }

    @include break-min($break-sm) {
      padding: 0 60px;
    }
  }
}

.project-wrapper.active {
  opacity: 1;

  .project {
    transform: translateY(0);
  
    .project-header {
      .title {
        opacity: 1;
      }
  
      .project-header-bg {
        transform: scaleX(1);
      }
    }
  }
}