
/*******************************
Other Projects (Home Page)
*******************************/

.other-projects {    
  @include break-min(960px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .other-project {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    width: 100%;

    @include break-min(960px) {
      width: calc(50% - 5px);
    }

    &::after {
      display: block;
      content: '';
      padding-bottom: 73%;
    }

    &.indinero-mobile {
      background-size: 90%;
      background-position: center bottom;
    }

    &.indinero-invoices {
      background-size: 95%;
      background-position: right bottom;
    }

    &.jive-blogs {
      background-position: center center;
    }

    &.jive-daily {
      background-size: 98%;
      background-position: center bottom;
    }

    &.jive-events {
      background-size: 97%;
      background-position: center bottom;
    }

    &.jive-calendar {
      background-size: 95%;
      background-position: center bottom;
    }

    .project-details {
      background: rgba($color-bg-dark, 0.9);
      position: absolute;
      bottom: -1px; // incase height doesn't round to full pixel
      left: 0;
      width: 100%;
      transform: translateY(100%);
      transition: 0.2s transform;
      padding: 40px;

      h3 {
        color: white;
        margin: 0 0 15px;
      }
      p {
        color: white;
        margin: 0;
        line-height: 28px;
      }
    }

    &:hover {
      .project-details {
        transform: translateY(0);
      }
    }
  }
}