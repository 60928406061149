
/*******************************
Variables
*******************************/

// Global

$padding-xsmall: 60px;
$padding-small: 60px;
$padding-med: 100px;
$padding-lrg: 150px;

$site-max-width: 1600px;
$content-width: 920px;
$header-fixed-height: 75px;

// Colors

$color-brand1: #15B3E7;
$color-brand2: #08181F;

$color-gray: #d4d4d4;
$color-silver: #ECF0F3;
$color-off-white: #F7F7F7;
$color-bg-dark: $color-brand2;
$color-highlight: $color-brand1;

$color-text: $color-brand2;
$color-text-dark: #000;

// Font

$light: 300;
$normal: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$xbold: 800;
$black: 900;